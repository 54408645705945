@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button {
    @apply rounded-full w-20 md:w-24 m-1 cursor-pointer flex items-center text-sm justify-center p-1 
  }
  /* .font-family-karla { font-family: karla; } */
  .bg-sidebar { background: #3d68ff; }
  .cta-btn { color: #3d68ff; }
  .upgrade-btn { background: #1947ee; }
  .upgrade-btn:hover { background: #BE123C; }
  .active-nav-link { background: #BE123C; }
  .account-link:hover { background: #BE123C; }
}
/* 
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
